import { render, staticRenderFns } from "./ShopProducts.vue?vue&type=template&id=347008eb&scoped=true&"
import script from "./ShopProducts.vue?vue&type=script&lang=js&"
export * from "./ShopProducts.vue?vue&type=script&lang=js&"
import style0 from "./ShopProducts.vue?vue&type=style&index=0&id=347008eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "347008eb",
  null
  
)

export default component.exports